import React from 'react';

import { Component } from 'react';
import DropDownWidget from "./DropDownWidget";

class App extends Component {
  _appData = null;
  
  constructor(props){
    super(props);
    this.state  = { };
  }
  
    render() {
      return (
        <div>
           <DropDownWidget jsonData={this.props.jsonData} />
        </div>
      );
    }
  
  
  get appData() {
    return this._appData;
  }
  
  set appData(value) {
    this._appData = value;
  }
}

export default App;
