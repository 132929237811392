import React, { Component } from 'react';
import './DropDownWidget.css';
import Indicator from "./Indicator";

class DropDownWidget extends Component {
  inputRefs = {
    dropDown            : React.createRef(),
    dropDownOptions     : React.createRef(),
    mainValField        : React.createRef(),
    pzDropDownWrapper   : React.createRef(),
  };
  
  constructor(props){
    super(props);
    
    this.state  = {
      dropDownChildren  : '',
      ignoredKeySuffix  : this.props.jsonData.ignoredKeySuffix,
      replaceInKeyRx    : this.props.jsonData.replaceInKeyRx
    };
  }
  
  componentDidMount(){
    /**
     * HIDE DROP-DOWN LIST OF SUGGESTED TERMS
     * WHEN YOU CLICK OUTSIDE THE ENTIRE WIDGET...
     * THIS IS A WORKAROUND FOR ON-CLICK-OUTSIDE EVENT
     * THAT I CONJURED UP ON THE FLY...
     * FEEL FREE TO IMPLEMENT A BETTER ONE IF YOU KNOW ANY ;-)
     */
    document.addEventListener('click', (e) => {
      const ddWrapper             = this.inputRefs.pzDropDownWrapper.current;
      if(!ddWrapper.contains(e.target)){
        this.removeSuggestedChildren(e);
      }
    });
    
    let defVal = this.props.jsonData.defaultValue;
    // console.log(defVal);
    for(let key in this.props.jsonData.suggestions){
      const objVal = this.props.jsonData.suggestions[key];
      if(typeof key === 'string'){
        if(key.trim() === (defVal+"").trim()){
          this.inputRefs.dropDown.current.value = objVal.trim();
          break;
        }
      }else{
        if(parseInt(key) === parseInt(defVal)){
          this.inputRefs.dropDown.current.value = objVal.trim();
          break;
        }
      }
    }
    // console.log(this.inputRefs.dropDown.current);
  }
  
  /**
   * BASED ON THE ENTERED TEXT ON THE MAIN INPUT FIELD,
   * THIS FILTERS THE RELEVANT MATCHES AND UPDATES THE RENDERED DROP-DOWN LIST
   * @param e
   * @returns {boolean}
   */
  updateDropDown(e){
    e.preventDefault();
    const suggestions   = this.props.jsonData.suggestions;
    const searchTerm    = this.inputRefs.dropDown.current.value;
    const rxSearch      = new RegExp(searchTerm, 'gi');
    let matches         = [];
    
    if(Object.keys(suggestions) && !suggestions.length ) {
      matches           = {};
      // WE ARE DEALING WITH AN OBJECT NOT ARRAY:
      for (let suggestionKey in suggestions) {
        const suggestion = suggestions[suggestionKey];
        if(rxSearch.test(suggestion)){
          matches[suggestionKey] = suggestion.trim();
        }
      }
    }else {
      suggestions.forEach((suggestion) => {
        if(rxSearch.test(suggestion)){
          matches.push(suggestion);
        }
      });
    }
    
    if(matches.length < 1){
      this.inputRefs.dropDown.current.value = '';
      this.inputRefs.mainValField.current.value = '';
      return false;
    }
    
    this.renderDropDownChildren(matches);
  }
  
  /**
   * UPDATES THE MAIN INPUT FIELD...
   * INJECTS THE VALUE OF THE CURRENT ITEM-ON-FOCUS FROM WITHIN THE DROP-DOWN LIST
   * @param e
   */
  updateMainTextFieLdValue(e){
    this.inputRefs.dropDown.current.value = e.target.value.trim();
    this.inputRefs.mainValField.current.value = e.target.getAttribute('data-value').trim();
    DropDownWidget.dispatchCustomEvent('DD_VALUE_CHANGED', this.inputRefs.dropDown.current, {'value' : this.inputRefs.dropDown.current.value});
  
  }
  
  /**
   * REMOVES THE VISIBLE LIST OF DROP-DOWN SUGGESTIONS.
   * @param e
   */
  removeSuggestedChildren(e){
    const dropDownChildren  = this.inputRefs.dropDownOptions.current;
    let dropDownClasses     = dropDownChildren.getAttribute('class');
    
    if(dropDownClasses.indexOf('pz-show') !== -1){
      dropDownClasses = dropDownClasses.replace('pz-show', 'pz-hide');
      dropDownChildren.setAttribute('class', dropDownClasses)
    }
    DropDownWidget.dispatchCustomEvent('DD_FILTERED_OPTIONS_REMOVED', this.inputRefs.dropDown.current, {'value' : null});
  }
  
  handleChildKeyUp(e){}
  
  resetToNull(e) {
    this.inputRefs.mainValField.current.value = '';
    this.inputRefs.dropDown.current.value = '';
    this.removeSuggestedChildren(e);
  }
  
  handleChildKeyDown(e){
    const targetField       = e.target;
    const targetParent      = targetField.parentNode;
    const keyCode       = e.which || e.keyCode;
    switch(keyCode) {
      case 9:         // TAB
        e.preventDefault();
        this.removeSuggestedChildren(e);
        break;
      
      case 13:        // ENTER
        e.preventDefault();
        this.removeSuggestedChildren(e);
        break;
      
      case 38:        // UP ARROW
        if(targetParent.previousSibling){
          targetParent.previousSibling.children[0].focus()
        }
        break;
      
      case 40:        // DOWN ARROW
        if(targetParent.nextSibling){
          targetParent.nextSibling.children[0].focus()
        }
        break;
      
      default:
        break;
    }
  }
  
  /**
   * DISPLAYS ALL POSSIBLE SUGGESTIONS...
   * @param e
   */
  showAllSuggestions(e){
    this.renderDropDownChildren(this.props.jsonData.suggestions);
    this.inputRefs.dropDown.current.focus();
    DropDownWidget.dispatchCustomEvent('DD_ALL_OPTIONS_SHOWING', this.inputRefs.dropDown.current, {'value' : null});
  }
  
  /**
   * PREVENTS DEFAULT BEHAVIOUR FOR THE "TAB" & "ENTER" KEYS
   * @param e
   */
  static preventTabAndEnterDefaults(e){
    const keyCode       = e.which || e.keyCode;
    if(keyCode === 9 || keyCode === 13){
      e.preventDefault();
    }
  }
  
  /**
   * SELECTS AN ITEM ON THE DISPLAY LIST THAT HAS CURRENT FOCUS
   * WHEN THE TAB OR ENTER KEY IS PRESSED
   * @param e
   */
  selectOnTabOrEnter(e){
    DropDownWidget.preventTabAndEnterDefaults(e);
    let firstInputField;
    try{
      firstInputField   = this.inputRefs.dropDownOptions.current.children[0].children[0];
    }catch(e){
      this.resetToNull(e);
      return false;
    }
    const keyCode           = e.which || e.keyCode;
    
    switch(keyCode) {
      case 9:         // TAB
        this.inputRefs.dropDown.current.value = firstInputField.value;
        e.target.value = this.inputRefs.dropDown.current.value;
        this.inputRefs.mainValField.current.value = this.inputRefs.dropDown.current.getAttribute('data-value');
        firstInputField.focus();
        DropDownWidget.dispatchCustomEvent('DD_TAB_PRESSED', this.inputRefs.dropDown.current, {'value' : firstInputField.value});
        DropDownWidget.dispatchCustomEvent('DD_VALUE_CHOSEN', this.inputRefs.dropDown.current, {'value' : firstInputField.value});
        DropDownWidget.dispatchCustomEvent('DD_VALUE_CHANGED', this.inputRefs.mainValField.current, {'value' : firstInputField.value});
        break;
      
      case 13:        // ENTER
        break;
      
      case 38:        // UP ARROW
        this.inputRefs.dropDown.current.value = firstInputField.value;
        e.target.value = this.inputRefs.dropDown.current.value;
        this.inputRefs.mainValField.current.value = this.inputRefs.dropDown.current.getAttribute('data-value');
        firstInputField.focus();
  
        DropDownWidget.dispatchCustomEvent('DD_ARROW_UP_PRESSED', this.inputRefs.dropDown.current, {'value' : firstInputField.value});
        DropDownWidget.dispatchCustomEvent('DD_VALUE_CHOSEN', this.inputRefs.dropDown.current, {'value' : firstInputField.value});
        DropDownWidget.dispatchCustomEvent('DD_VALUE_CHANGED', this.inputRefs.mainValField.current, {'value' : firstInputField.value});
        break;
      
      case 40:        // DOWN ARROW
        this.inputRefs.dropDown.current.value = firstInputField.value;
        e.target.value = this.inputRefs.dropDown.current.value;
        this.inputRefs.mainValField.current.value = this.inputRefs.dropDown.current.getAttribute('data-value');
        firstInputField.focus();
        DropDownWidget.dispatchCustomEvent('DD_ARROW_DOWN_PRESSED', this.inputRefs.dropDown.current, {'value' : firstInputField.value});
        DropDownWidget.dispatchCustomEvent('DD_VALUE_CHOSEN', this.inputRefs.dropDown.current, {'value' : firstInputField.value});
        DropDownWidget.dispatchCustomEvent('DD_VALUE_CHANGED', this.inputRefs.mainValField.current, {'value' : firstInputField.value});
        break;
      
      default:
        break;
    }
  }
  
  
  static dispatchCustomEvent(eventName, domElement, payload = null) {
    // var event = new Event('build');
    const event = new CustomEvent(eventName, {detail: payload});
    domElement.dispatchEvent(event);
    
    // window.console.log(eventName + ' was dispatched with payload: ...');
    // window.console.log(payload);
  }
  
  /**
   * BUILDS THE SUGGESTION LIST BASED ON INPUT TEXT (MATCHED STRING)
   * @param matches
   */
  renderDropDownChildren(matches){
    let ddc;
    let key;
    let cue = 0;
    const dropDownChildren  = this.inputRefs.dropDownOptions.current;
    let dropDownClasses     = dropDownChildren.getAttribute('class');
    
    if(dropDownClasses.indexOf('pz-hide') !== -1){
      dropDownClasses = dropDownClasses.replace('pz-hide', 'pz-show');
      dropDownChildren.setAttribute('class', dropDownClasses)
    }
    
    if(Object.keys(matches) && !matches.length ){
      // console.log(matches);
      // WE ARE DEALING WITH AN OBJECT NOT ARRAY:
      ddc = [];
      for(key in matches){
        const cssClass = ((matches[key].trim() === this.inputRefs.dropDown.current.value)  ||
          (key.trim() === this.inputRefs.mainValField.current.value) ) ? ' pz-is-selected' : '';
        cue++;
        
        if(this.state.ignoredKeySuffix){
          if( (new RegExp(this.state.ignoredKeySuffix)).test(key.trim()) ){
            ddc.push(
              (<div className='pz-child-suggestion-box pz-null-action-box' key={`${key.trim()}-${cue}`}>
                <input type='text'
                       readOnly={true}
                       data-value={key}
                       className={`pz-suggestion-input${cssClass}`}
                       onFocus={(e)=>{this.resetToNull(e)}}
                       onClick={(e)=>{this.resetToNull(e)}}
                       value={matches[key]} />
              </div>)
            );
          }else{
            ddc.push(
              (<div className='pz-child-suggestion-box' key={`${key.trim()}-${cue}`}>
                <input type='text'
                       readOnly={true}
                       data-value={key}
                       className={`pz-suggestion-input${cssClass}`}
                       onFocus={(e)=>{this.updateMainTextFieLdValue(e)}}
                       onClick={(e)=>{this.removeSuggestedChildren(e)}}
                       onKeyUp={(e)=>{this.handleChildKeyUp(e)}}
                       onKeyDown={(e)=>{this.handleChildKeyDown(e)}}
                       value={matches[key]} />
              </div>));
          }
        }else{
          ddc.push(
            (<div className='pz-child-suggestion-box' key={`${key.trim()}-${cue}`}>
              <input type='text'
                     readOnly={true}
                     data-value={key}
                     className={`pz-suggestion-input${cssClass}`}
                     onFocus={(e)=>{this.updateMainTextFieLdValue(e)}}
                     onClick={(e)=>{this.removeSuggestedChildren(e)}}
                     onKeyUp={(e)=>{this.handleChildKeyUp(e)}}
                     onKeyDown={(e)=>{this.handleChildKeyDown(e)}}
                     value={matches[key]} />
            </div>)
          );
        }
      }
      
    }else{
      ddc = matches.map((suggestion, cueKey) => {
        return(
          <div className='pz-child-suggestion-box' key={cueKey}>
            <input type='text'
                   readOnly={true}
                   data-value={suggestion.trim()}
                   className='pz-suggestion-input'
                   onFocus={(e)=>{this.updateMainTextFieLdValue(e)}}
                   onClick={(e)=>{this.removeSuggestedChildren(e)}}
                   onKeyUp={(e)=>{this.handleChildKeyUp(e)}}
                   onKeyDown={(e)=>{this.handleChildKeyDown(e)}}
                   value={suggestion.trim()} />
          </div>
        )
      });
    }
    this.setState({
      dropDownChildren : ddc,
    });
    
    DropDownWidget.dispatchCustomEvent('DD_OPTIONS_RENDERED', dropDownChildren, {'value' : null});
  }
  
  render() {
    let defVal = this.props.jsonData.defaultValue;
    if(Object.keys(this.props.jsonData.suggestions) && !this.props.jsonData.suggestions.length ){
      defVal = this.props.jsonData.suggestions[this.props.jsonData.defaultValue];
    }
    
    return (
      <div className="pz-drop-down-wrapper" ref={this.inputRefs.pzDropDownWrapper}>
        <div className="pz-drop-down">
          <input type="text"
                 id={this.props.jsonData.fieldName}
                 className={this.props.jsonData.className}
                 placeholder={this.props.jsonData.placeholder}
                 autoComplete= "off"
                 defaultValue={defVal}
                 ref={this.inputRefs.dropDown}
                 onChange={ (e)=>{ this.updateDropDown(e)}}
                 onKeyUp={ (e)=>{ this.selectOnTabOrEnter(e)}}
                 onKeyDown={ (e)=>{ DropDownWidget.preventTabAndEnterDefaults(e)}} />
          <Indicator showAllSuggestions={ e => this.showAllSuggestions(e) }/>
          
          <input type="hidden" name={this.props.jsonData.fieldName} ref={this.inputRefs.mainValField} defaultValue={this.props.jsonData.defaultValue}/>
        </div>
        <div className="pz-drop-down-children pz-hide"
             ref={this.inputRefs.dropDownOptions}>
          { this.state.dropDownChildren }
        </div>
      </div>
    );
  }
}

export default DropDownWidget;

