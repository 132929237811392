import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

class PzRenderer{
  _domID    = null;
  _appData  = null;
  
  constructor(domID=null, appData=null) {
    this._domID   = domID;
    this._appData = appData;
  }
  
  renderToView (domID=null, appData=null) {
    this._domID   = domID   ? domID   : this.domID;
    this._appData = appData ? appData : this.appData;
    ReactDOM.render(<App jsonData={this.appData}/>, document.getElementById(this.domID));
  };
  
  get domID() {
    return this._domID;
  }
  
  set domID(value) {
    this._domID = value;
  }
  
  get appData() {
    return this._appData;
  }
  
  set appData(value) {
    this._appData = value;
  }
}

export default PzRenderer;
